#us {
    position: relative;
    max-width: 100%;
    background-image: url("/assets/images/us/us-page-bg-mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;

    .content {
        text-align: center;
        margin: 0 5%;
        padding-top: 20px;
        .first-ratio-image {
            width: 67.27px;
        }
        .second-ratio-image {
            width: 53.65px;
        }
        .third-ratio-image {
            width: 61.66px;
        }
        .fourth-ratio-image {
            width: 29.9px;
        }
        .logo {
            display: none;
        }
        img {
            width: 22%;
            cursor: pointer;
        }
    }
    .ant-modal-mask {
        background: unset !important;
        backdrop-filter: blur(2px);
        top: 50px !important;
    }

    .us-page-modal-img {
        padding: 0;
        .ant-modal-content {
            background: none;
            box-shadow: none;

            .ant-modal-close {
                color: transparent;
            }
            .ant-modal-body {
                padding: 0;
                background: transparent;
                .us-page-modal-img-wrapper {
                    .filtered-image {
                        width: 100%;
                    }
                }
            }
        }
    }

    @media (min-width: 768px) {
        background-image: url("/assets/images/us/us-page-bg.png");
        .ant-modal-mask {
            top: 110px !important;
        }
        .content {
            .first-ratio-image {
                width: 100px;
            }
            .second-ratio-image {
                width: 90px;
            }
            .third-ratio-image {
                width: 85px;
            }
            .fourth-ratio-image {
                width: 50px;
            }
            img {
                margin-left: -20px;
                margin-top: -10px;
            }
        }

        .ant-modal-mask {
            top: 110px !important;
        }
    }

    @media (min-width: 1024px) {
        .content {
            .first-ratio-image {
                width: 119px;
            }
            .second-ratio-image {
                width: 100px;
            }
            .third-ratio-image {
                width: 110px;
            }
            .fourth-ratio-image {
                width: 55px;
            }
        }
    }

    @media (min-width: 1200px) {
        .content {
            .first-ratio-image {
                width: 138px;
            }
            .second-ratio-image {
                width: 110px;
            }
            .third-ratio-image {
                width: 126px;
            }
            .fourth-ratio-image {
                width: 61px;
            }
        }
    }
}
