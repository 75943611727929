#about-us {
  position: relative;
  max-width: 100%;

  .content {
    position: relative;
    width: 100%;

    .triangle-big-box {
      position: relative;
      width: 100%;
      margin: 0 auto;
      height: 70%;
      padding-top: 60px;

      .triangle-content {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 60%;
        z-index: -1;
        transform: translate(-50%, -50%);
        padding: 70px 30px;
      }

      .left-arrow {
        padding: 10px;
        position: absolute;
        left: 10%;
        top: 45%;
        z-index: 9999;
      }
      .right-arrow {
        padding: 10px;
        position: absolute;
        right: 10%;
        top: 45%;
        z-index: 9999;
      }
    }

    .mobile {
      .mobile-items {
        position: absolute;
      }
      .top-left {
        top: 0;
        left: 20px;
      }
      .top-right {
        top: 20px;
        right: 10px;
      }
      .bottom-left {
        bottom: 20px;
        left: 10px;
      }
      .bottom-right {
        bottom: 40px;
        right: 20px;
        width: 125px;
      }
    }
    .creative {
      .creative-items {
        position: absolute;
      }
      .creative-1 {
        top: 0;
        left: 20%;
      }
      .creative-2 {
        top: 20%;
        left: 5%;
      }
      .creative-3 {
        bottom: 15%;
        left: 15%;
      }
      .creative-4 {
        top: 0;
        right: 15%;
      }
      .creative-5 {
        top: 20%;
        right: 5%;
      }
      .creative-6 {
        bottom: 10%;
        right: 20%;
      }
    }
    .people {
      .people-items {
        position: absolute;
      }
      .people-1 {
        top: 10%;
        left: 15%;
      }
      .people-2 {
        bottom: 10%;
        left: 10%;
      }
      .people-3 {
        top: 15%;
        right: 7%;
      }
      .people-4 {
        bottom: 10%;
        right: 10%;
      }
    }
  }

  @media (max-width: 767.9px) {
    .content {
      height: calc(100% - 100px);

      .triangle-big-box {
        max-width: 600px;
      }
     

      .triangle-mobile {
        background: url("/assets/images/about-us/about-us-mobile-mobile.png");
        background-repeat: no-repeat;
        background-position: -70px 60%;
      }
      .triangle-people {
        background-image: url("/assets/images/about-us/about-us-people-mobile.png");
        background-repeat: no-repeat;
        background-position: 20px 120%;
        background-size: contain;
      }
      .triangle-creative {
        background: url("/assets/images/about-us/about-us-creative-mobile.png");
        background-repeat: no-repeat;
        background-position: 90% 30px;
      }

      .mobile {
        .mobile-items {
          position: absolute;
        }
        .top-left {
          width: 125px;
        }
        .top-right {
          width: 125px;
        }
        .bottom-left {
          width: 125px;
        }
        .bottom-right {
          width: 125px;
        }
      }
      .creative {
        .creative-items {
          width: 80px;
        }
      }
      .people {
        .people-items {
          width: 100px;
        }
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1023.99px) {
    .content {
      .triangle-big-box {
        max-width: 600px;
      }
      .triangle-content {
        padding: 75px !important;
      }
      .triangle-mobile {
        background: url("/assets/images/about-us/about-us-mobile-mobile.png");
        background-repeat: no-repeat;
        background-position: -45px 60%;
        background-size: contain;
      }
      .triangle-people {
        background-image: url("/assets/images/about-us/about-us-people-mobile.png");
        background-repeat: no-repeat;
        background-position: 20px 139%;
        background-size: contain;
      }
      .triangle-creative {
        background: url("/assets/images/about-us/about-us-creative-mobile.png");
        background-repeat: no-repeat;
        background-position: 110% 30px;
        background-size: contain;
      }
      .mobile {
        .mobile-items {
          position: absolute;
        }
        .top-left {
          width: 200px;
        }
        .top-right {
          width: 150px;
        }
        .bottom-left {
          width: 150px;
        }
        .bottom-right {
          width: 200px;
        }
      }
      .creative {
        .creative-items {
          width: 120px;
        }
      }
      .people {
        .people-items {
          width: 120px;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .content {
      .triangle-big-box {
        max-width: 750px;
        .left-arrow {
          height: 100px;
          padding: 20px !important;
        }
        .right-arrow {
          height: 100px;
          padding: 20px !important;
        }
      }
      .triangle-content {
        padding: 120px !important;
        padding-bottom: 150px !important;
      }
      .triangle-mobile {
        background: url("/assets/images/about-us/about-us-mobile-mobile.png");
        background-repeat: no-repeat;
        background-position: -45px 60%;
        background-size: contain;
      }
      .triangle-people {
        background-image: url("/assets/images/about-us/about-us-people-mobile.png");
        background-repeat: no-repeat;
        background-position: 20px 133%;
        background-size: contain;
      }
      .triangle-creative {
        background: url("/assets/images/about-us/about-us-creative-mobile.png");
        background-repeat: no-repeat;
        background-position: 110% 30px;
        background-size: contain;
      }
      .mobile {
        .mobile-items {
          position: absolute;
        }
        .top-left {
          width: 285px;
          left: 120px;
        }
        .top-right {
          width: 200px;
          top: 20px;
          right: 235px;
        }
        .bottom-left {
          width: 200px;
          bottom: 60px;
          left: 125px;
        }
        .bottom-right {
          bottom: 70px !important;
          width: 275px;
        }
      }
      .creative {
        .creative-items {
          width: 165px;
        }
        .creative-3 {
          bottom: 15%;
          left: 10%;
        }
        .creative-6 {
          bottom: 10%;
          right: 10%;
        }
      }
      .people {
        .people-items {
          width: 170px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .content {
      height: calc(100% - 110px);
    }
  }
}
