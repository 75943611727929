#home {
  max-width: 100%;

  .regular-text {
    font-weight: lighter;
  }

  .extra-bold {
    font-weight: 900;
  }

  .bold {
    font-weight: 500;
  }

  .bold-text {
    font-weight: 700;
  }

  .italic {
    font-style: italic;
  }

  .we-do {
    padding: 0 10px;
    margin: 0 20px 0 20px;
    margin-top: calc(100px + 2vw);
    position: relative;

    .head-text {
      position: absolute;
      width: calc(30px + 2vw);
      left: 0px;
      // top: calc(-55px - 2vw);
      top: calc(-65px - 2vw);
      font-size: 15px;
      color: #3c3838;
    }

    .we-do-content {
      display: none;
    }
    .we-do-content,
    .we-do-content-mobile {
      font-size: calc(9px + 2.5vw);
      font-weight: 400;

      .we-do-lines {
        display: flex;
        justify-content: space-between;
        height: 40px;

        @media (min-width: 0) {
          .smaller-text {
            font-size: calc(7px + 2.5vw);
          }
        }
        @media (min-width: 374px) {
          .smaller-text {
            font-size: calc(9px + 2.5vw);
          }
        }
      }

      .communication {
        position: relative;
      }

      img {
        height: calc(30px + 4vw);
        transform: translate(0, -15px);
      }

      .idea {
        position: relative;
        margin-right: 75px;
        .design {
          position: absolute;
          bottom: -10px;
          // right: -90px;
          height: calc(80px + 8vw);
          z-index: -1;

          @media (min-width: 0) {
            margin-left: 30px;
            height: calc(65px + 8vw);
          }
          @media (min-width: 374px) {
            height: calc(80px + 8vw);
          }
          @media (min-width: 767px) {
            margin-left: 0;
          }
        }
      }

      .glass-meme {
        margin-left: 20px;
        position: relative;
        img {
          position: absolute;
          left: -25px;
          top: 0px;
          height: calc(15px + 4vw);
        }
      }

      .firebox {
        position: relative;
        .fire-design {
          position: absolute;
          left: -91px;
          top: -165px;
          z-index: -1;
          height: 220px;
        }
        img {
          transform: translate(0, -5px);
        }
      }

      .expectations {
        margin-right: 60px;
      }

      .data {
        height: calc(25px + 3vw);
        transform: translate(0, 5px);
      }

      .skate-box {
        position: relative;
        width: 100px;
        padding-left: 40px;

        .skate-text {
          position: absolute;
          left: 5px;
          top: -1px;
          z-index: 1000;
        }

        .skate {
          transform: translate(0, 0);
          z-index: -1;
        }
      }
      .coffee-box {
        position: relative;
        .coffee {
          position: absolute;
          top: -40px;
          left: 5px;
          transform: translate(0, 5px);
          z-index: -1;
        }
      }
    }
  }
  .we-dont {
    display: none;
  }
  .we-dont-mobile {
    padding-bottom: 20px;
  }
  .we-dont-mobile,
  .we-dont {
    width: 100%;
    //margin-top: 55px;
    margin-top: 65px;
    position: relative;
    line-height: 130%;
    font-size: calc(12px + 2vw);

    .head-text-dont {
      position: absolute;
      width: calc(30px + 2vw);
      left: 20px;
      //top: calc(-50px - 2vw);
      top: calc(-65px - 2vw);
      font-size: 15px;
      color: #3c3838;
      line-height: 1.5715;

      @media (min-width: 1024px) {
        top: calc(-50px - 2vw);
        left: 8px;
      }
    }

    .we-dont-lines {
      //padding: 0 15px;
      padding: 0 35px;
      display: flex;
      //justify-content: space-around;
      justify-content: space-between;
      background: url("/assets/images/red-bg.png");
      background-position-y: center;
      background-size: 100% 60%;

      @media (min-width: 0) {
        .smaller-text {
          font-size: calc(9px + 2vw);
        }
      }
      @media (min-width: 374px) {
        .smaller-text {
          font-size: calc(12px + 2vw);
        }
      }
    }
  }

  @media (min-width: 768px) {
    .we-do {
      margin-top: calc(130px + 2vw) !important;
    }

    .we-do-content-mobile {
      display: none;
    }
    .we-do-content {
      display: block !important;
      font-size: calc(6px + 2vw) !important;
      .idea {
        margin-right: 60px !important;
        .design {
          height: 150px !important;
          left: -40px;
        }
      }
      .glass-meme {
        margin: 0 !important;
        img {
          position: relative !important;
          margin: 0 -5px !important;
          left: 0 !important;
          height: calc(5px + 4vw) !important;
        }
      }
      .we-do-lines {
        height: 69px !important;
      }
      .fire-design {
        left: -20px !important;
        top: -195px !important;
        height: 280px !important;
      }
      .skate-box {
        padding-left: 0 !important;

        .skate-text {
          left: -25px !important;
        }
      }
      .coffee-box {
        position: relative;
        width: 130px;
        margin-left: 20px;
        .coffee-text {

        }
        .coffee {
          position: absolute !important;
          top: 0px !important;
          left: 50px !important;
        }
      }
    }
    .we-dont-mobile {
      display: none !important;
    }
    .we-dont {
      display: block !important;
      .we-dont-lines {
        background: url("/assets/images/red-bg-big.png");
        background-position-y: center;
        background-size: 100% 60%;
      }
      .firsted {
        // padding-right: 60px !important;
      }
      .lefted {
        justify-content: start;
        padding-left: 30px;
      }
    }
  }

  @media (min-width: 1024px) {
    .we-do-box {
      margin: 0 auto;
      max-width: 1024px;
      .we-do-content {
        font-size: 27px !important;
        img {
          height: 76px;
        }
        .glass-meme {
          margin-left: 20px;
          img {
            height: 52px !important;
          }
        }
        .data {
          height: calc(25px + 3vw);
          transform: translate(0, 5px);
        }

        .coffee-box {
          position: relative;
          .coffee {
            position: absolute;
            top: -40px;
            left: 5px;
            transform: translate(0, 5px);
            z-index: -1;
          }
        }
      }
    }
    .we-do {
      margin: 0 60px 0 45px !important;
      margin-top: calc(100px + 2vw) !important;
    }
    .head-text {
      left: -37px !important;
      top: calc(-45px - 2vw) !important;
    }
    .we-dont {
      font-size: 34px !important;
    }
  }

  @media (min-width: 0) {

    #home-popup-icon {
      cursor: pointer;
      width: 120px;
      height: 120px;
      display: flex;
      margin-left: auto;
    }
  }

  @media (min-width: 1320px) {

    #home-popup-icon {
      width: 144px;
      height: 144px;
      position: absolute;
      right: 0;
      top: 525px;
    }
  }
}
