#contact {
  position: relative;
  max-width: 100%;

  .contact-content {
    width: 100%;
    // height: calc(100% - 180px);
    height: calc(100% - 110px);
    background-image: url("/assets/images/map.png");
    background-position: center;
    background-size: cover;
    .contact-image {
      position: absolute;
      left: 50%;
      top: 50%;
      min-width: 250px;
      max-width: 400px;
      transform: translate(-50%, -50%);

      img {
        width: 100%;
      }
    }
  }

  @media (max-width: 767.9px) {
    .contact-content {
      height: calc(100% - 180px);
    }
  }

  @media (min-width: 768px) {
    // .contact-content {
    //   height: calc(100% - 290px);
    // }
  }
}
