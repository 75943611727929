.home-modal {

  .ant-modal-content {

    .ant-modal-body {
      background: white;
      padding: 0;

      .body {

        .background {
          width: 100%;
          height: auto;
        }

        .icon {
          position: absolute;
          bottom: 0;
          right: 0;
        }

        .content {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: #262B65;

          .title {
            font-weight: bold;
            line-height: 130%;
          }

          .desc-1,
          .desc-2 {
            line-height: 130%;
          }

          .desc-1 {
            margin: 16px 0;
          }

          .desc-2 {
            font-weight: 500;
          }
        }
      }
    }
  }

  @media (min-width: 0) {

    .ant-modal-content {

      .ant-modal-body {

        .body {

          .icon {
            width: 70.5px;
            height: 70.5px;
          }

          .content {

            .title {
              font-size: 14px;
            }

            .desc-1,
            .desc-2 {
              font-size: 12px;
            }

            .desc-1 {
            }

            .desc-2 {
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {

    .ant-modal-content {

      .ant-modal-body {

        .body {

          .icon {
            width: 112px;
            height: 112px;
          }

          .content {

            .title {
              font-size: 22px;
            }

            .desc-1,
            .desc-2 {
              font-size: 16px;
            }

            .desc-1 {
            }

            .desc-2 {
            }
          }
        }
      }
    }
  }
}
