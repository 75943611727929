#products {
  position: relative;
  max-width: 100%;

  .content {
    position: relative;
    width: 100%;
    // background: url("/assets/images/products/products-bg.png");
    // background-size: contain;
    // background-repeat: no-repeat;
    // background-position:

    .left-arrow {
      position: absolute;
      top: 50%;
      left: 2%;
      cursor: pointer;
    }

    .right-arrow {
      position: absolute;
      top: 50%;
      right: 2%;
      cursor: pointer;
    }
    .zero,
    .first,
    .second {
      width: 100%;
      height: 100%;
      text-align: center;
    }

    .card-info {
      max-height: 105px;
    }

    .card-image,
    .card-info {
      display: block;
      margin: 10px auto;
      float: left;
    }
    .card-text {
      font-weight: 500;
    }
    .product-card-content {
      text-align: -webkit-center;
    }

    .product-card {
      width: min-content;
      height: 100%;
    }

    .second-content {
      display: flex;
      flex-direction: column;
    }

    .info-card-lines {
      list-style: none;
    }

    .ant-carousel {
      .slick-list {
        .slick-track {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .slick-dots {
        &.slick-dots-bottom {
          bottom: -15px;
          $size: 12px;

          li {
            width: $size;

            &.slick-active {
              button {
                background: black;
              }
            }

            button {
              width: $size;
              height: $size;
              opacity: 1;
              border: 2px solid black;
              border-radius: 100px;
            }
          }
        }
      }
    }
  }
  .products-footer {
    text-align: center;
    background: url("../../assets/images/product/product_1.png");
    background-position: left;
    height: 270px;
    background-size: auto 134%;
    background-repeat: no-repeat;
    .products-footer-row {
      height: 100%;
      .products-footer-title {
        // background: url("../../assets/images/product/product.png");
        // background-position: center;
        // background-size: auto 90%;
        display: flex;
        background-repeat: no-repeat;
        align-items: center;
        justify-content: center;
        height: 150px;
        font-size: 18px;
      }
      .footer-image-content {
        text-align: left;
        .footer-image {
          justify-content: space-between;
        }
      }
    }
  }

  @media (max-width: 767.9px) {
    .products-footer {
      background-size: auto 70%;
      display: flex;
      flex-direction: row;
      height: 150px;
      .products-footer-row {
        height: 100%;
        .products-footer-title {
          // background: url("../../assets/images/product/product.png");
          // background-position: center;
          // background-size: auto 90%;
          display: flex;
          background-repeat: no-repeat;
          align-items: center;
          justify-content: center;
          height: 100%;
          font-size: 10px;
          width: max-content;
        }

        .footer-image-content {
          text-align: left;
        }
      }
    }
    .content {
      height: 550px;
      .zero {
        .card-image {
          height: 30px;
        }
        .card-info {
          width: 100%;
          min-width: 120px;
        }
      }

      .first {
        .card-image {
          height: 70px;
        }
        .card-info {
          width: 100%;
          min-width: 120px;
        }
      }

      .second {
        .card-image {
          width: 50px;
        }
        .wide {
          width: 65px;
        }
      }
    }
    .footer-image-content {
      text-align: center !important;
    }

    .footer-image-fenerbahce {
      height: 25px;
    }

    .footer-image-turk-telekom {
      height: 30px;
      margin-top: 0;
    }

    .footer-image-vodafone {
      height: 35px;
      margin-top: 0px;
    }

    .footer-image {
      margin: 10px 4px;
      height: 10px;
    }

    .right-arrow,
    .left-arrow {
      z-index: 1;
    }

    .row {
      margin: 0;
    }
  }

  @media (min-width: 768px) and (max-width: 1023.99px) {
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70vh;
      margin-bottom: 3vw;
      .zero {
        .card-image {
          height: 70px;
        }
        .card-info {
          width: 100%;
        }
      }
      .first {
        .card-image {
          height: 100px;
        }
        .card-info {
          width: 100%;
        }
      }
      .second {
        .card-image {
          width: 90px;
        }
        .wide {
          width: 110px;
        }
      }
    }

    .footer-image-fenerbahce {
      height: 45px;
    }

    .footer-image-turk-telekom {
      height: 70px;
      margin-top: 0;
    }

    .footer-image-vodafone {
      height: 55px;
      margin-top: 0px;
      margin-left: -10px;
    }

    .footer-image {
      margin: 10px;
      height: 30px;
    }
  }

  @media (min-width: 1024px) {
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 77vh;
      .zero {
        .card-image {
          height: 80px;
        }
      }
      .first {
        .card-image {
          height: 120px;
        }
      }

      .second {
        .card-image {
          width: 120px;
        }
        .wide {
          width: 140px;
        }
      }
    }

    .footer-image-fenerbahce {
      height: 45px;
    }

    .footer-image-turk-telekom {
      height: 70px;
      margin-top: 0;
    }

    .footer-image-vodafone {
      height: 55px;
      margin-top: 0px;
      margin-left: -10px;
    }

    .footer-image {
      margin: 15px;
      height: 30px;
    }
  }
}

.ant-carousel {
  width: -webkit-fill-available;
  align-self: center;
  padding-left: 10px;
}
