#our-people {
  position: relative;
  max-width: 100%;

  .everybody-content {
    height: 100%;
    .everybody-content-img {
      height: 100%;
    }
    .everybody-content-text {
      text-align: center;
      span {
        font-weight: 500;
      }
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 10vh - 100px);
    background-repeat: no-repeat;
    background-position: center;
    min-height: 400px;
    .top,
    .bottom {
      height: 50%;
    }

    .line-content-body {
      margin-left: -4px;
      display: flex;
      flex-direction: inherit;
    }
    .info-img {
      width: 20px;
    }
    .people-boxes {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      img {
        cursor: pointer;
      }
    }
    .people-big-box {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      // z-index: -2;
      .line-content {
        a {
          color: #000000D9;
        }
      }
      .person-info-card {
        z-index: 1;
      }
    }
  }

  @media (max-width: 767.9px) {
    .everybody-content {
      height: 100%;
      .everybody-content-img {
        height: 100%;
      }
      .everybody-content-text {
        text-align: center;
        span {
          font-weight: 500;
          font-size: 10px;
        }
      }
    }

    .people-boxes {
      #top-left-0 {
        position: absolute;
        left: 5%;
        top: 18%;
      }
      #top-left-1 {
        position: absolute;
        left: 50%;
        top: 5%;
      }
      #top-left-2 {
        position: absolute;
        left: 25%;
        top: 7%;
      }
      #top-left-3 {
        position: absolute;
        left: 35%;
        top: 30%;
      }
      #top-left-5 {
        position: absolute;
        left: 5%;
        top: 42%;
      }
      #bottom-left-6 {
        position: absolute;
        left: 45%;
        top: -25%;
      }
      #bottom-right-9 {
        position: absolute;
        right: 20%;
        top: -25%;
      }
      #bottom-right-10 { //büşra
        // position: absolute;
        // left: 30%;
        // top: -45%;
        position: absolute;
        right: 15%;
        top: -25%;
      }
      #bottom-right-18 { //seçil
        // position: absolute;
        // left: 5%;
        // top: -20%;
        // width: calc(25px + 5vw);
        position: absolute;
        right: 45%;
        top: -27%;
      }
      #bottom-left-21 {
        position: absolute;
        left: 40%;
        top: -5%;
      }
      #bottom-left-19 {
        position: absolute;
        left: 5%;
        top: 5%;
      }
      #bottom-left-7 {
        position: absolute;
        left: 30%;
        top: 20%;
      }
      #bottom-left-26 {
        position: absolute;
        left: 8%;
        top: 35%;
      }
      #bottom-left-8 {
        position: absolute;
        left: 30%;
        top: 50%;
      }
      #bottom-left-27 {
        position: absolute;
        left: 10%;
        top: 65%;
      }
      #bottom-left-17 {
        position: absolute;
        left: 40%;
        top: 75%;
      }
      #bottom-left-10 {
        position: absolute;
        left: 45%;
        top: 40%;
      }
      #top-right-32 {
        position: absolute;
        right: 50%;
        top: 5%;
      }
      #top-right-12 {
        position: absolute;
        right: 25%;
        top: 8%;
      }
      #top-right-22 {
        position: absolute;
        right: 40%;
        top: 25%;
        width: calc(25px + 5vw);
      }
      #top-right-13 {
        position: absolute;
        right: 15%;
        top: 30%;
      }
      #top-right-14 {
        position: absolute;
        right: 35%;
        top: 47%;
      }
      #top-right-23 {
        position: absolute;
        right: 55%;
        top: 55%;
      }
      #top-right-9 {
        position: absolute;
        right: 10%;
        top: 60%;
      }
      #bottom-left-16 { //Murat
        // position: absolute;
        // right: 45%;
        // top: -27%;
        position: absolute;
        left: 5%;
        top: -20%;
        width: calc(25px + 5vw);
      }
      #bottom-left-4 { //serra
        // position: absolute;
        // right: 15%;
        // top: -25%;
        position: absolute;
        left: 30%;
        top: -45%;
      }
      #bottom-right-29 {
        position: absolute;
        right: 35%;
        top: -5%;
        width: calc(25px + 5vw);
      }
      #bottom-right-20 {
        position: absolute;
        right: 12%;
        top: 0%;
      }
      #bottom-right-11 {
        position: absolute;
        right: 25%;
        top: 15%;
      }
      #bottom-right-25 {
        position: absolute;
        right: 10%;
        top: 25%;
      }
      #bottom-right-30 {
        position: absolute;
        right: 38%;
        top: 30%;
      }
      #bottom-right-24 {
        position: absolute;
        right: 25%;
        top: 41%;
      }
      #bottom-right-28 {
        position: absolute;
        right: 5%;
        top: 50%;
      }
      #bottom-right-31 {
        position: absolute;
        right: 35%;
        top: 58%;
      }
      #bottom-right-15 {
        position: absolute;
        right: 15%;
        top: 68%;
      }

      img {
        width: calc(20px + 4vw);
      }
    }

    .people-1 {
      background: url("/assets/images/people/background/top-left-mobile.svg");
      background-position: -20vw -20vh;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .people-2 {
      background: url("/assets/images/people/background/top-right-mobile.svg");
      background-position: 31vw -20vh;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .people-3 {
      background: url("/assets/images/people/background/bottom-left-mobile.svg");
      background-position: -20vw 4vh;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .people-4 {
      background: url("/assets/images/people/background/bottom-right-mobile.svg");
      background-position: 30vw calc(50px + 5vh);
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .top-left-box.show-bg {
      background: url("/assets/images/people/background/top-left-mobile.svg");
      background-position: 60% 90%;
      background-repeat: no-repeat;
    }
    .top-right-box.show-bg {
      background: url("/assets/images/people/background/top-right-mobile.svg");
      background-position: left;
      background-repeat: no-repeat;
    }
    .bottom-left-box.show-bg {
      background: url("/assets/images/people/background/bottom-left-mobile.svg");
      background-position: 60% 0%;
      background-repeat: no-repeat;
    }
    .bottom-right-box.show-bg {
      background: url("/assets/images/people/background/bottom-right-mobile.svg");
      background-position: 0% 100%;
      background-repeat: no-repeat;
    }

    .people-info {
      position: absolute;
      z-index: 1;
      width: 150px;
      height: 140px;
      left: 50%;
      top: 40%;
      transform: translate(-50%, 0);
      .info-image {
        position: absolute;
        z-index: 0;
        height: 100%;
        left: 50%;
        transform: translate(-50%, 0);
      }
      .person-info-card {
        margin-top: 140px;
        padding: 0;
        font-size: 10px;
        font-weight: 900;

        .info-card-lines {
          display: flex;
          padding: 2px;
          font-weight: 500;
          list-style: none;
          border: 1px solid rgb(43, 43, 43);
          border-bottom: none;
          overflow: hidden;

           &.unvisible {
             display: none;
           }
        }
        .bottom-line {
          border: 1px solid rgb(43, 43, 43);
        }
        .line-content-head {
          width: 65px;
        }
        .line-content-body {
          display: flex;
          flex-direction: column;
        }
        .line-content {
          width: 79px;
          font-weight: 400;
          font-size: 9px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .content {
      .line-content-body {
        margin-left: -7px;
      }
      .info-img {
        width: 25px;
      }
    }
    .people-boxes {
      #top-left-0 {
        position: absolute;
        left: 5%;
        top: 18%;
      }
      #top-left-1 {
        position: absolute;
        left: 50%;
        top: 5%;
      }
      #top-left-2 {
        position: absolute;
        left: 25%;
        top: 7%;
      }
      #top-left-3 {
        position: absolute;
        left: 35%;
        top: 30%;
      }
      #top-left-5 {
        position: absolute;
        left: 5%;
        top: 47%;
      }
      #bottom-left-6 {
        position: absolute;
        left: 45%;
        top: -25%;
      }
      #bottom-left-10 {
        position: absolute;
        left: 45%;
        top: 40%;
      }
      #bottom-right-10 { //büşra
        // position: absolute;
        // left: 30%;
        // top: -38%;
        position: absolute;
        right: 20%;
        top: -20%;
      }
      #bottom-right-18 { //seçil
        // position: absolute;
        // left: 5%;
        // top: -25%;
        // width: calc(25px + 5vw);
        position: absolute;
        right: 45%;
        top: -25%;
      }
      #bottom-left-21 {
        position: absolute;
        left: 27%;
        top: -5%;
      }
      #bottom-left-19 {
        position: absolute;
        left: 5%;
        top: 5%;
      }
      #bottom-left-7 {
        position: absolute;
        left: 37%;
        top: 23%;
      }
      #bottom-left-26 {
        position: absolute;
        left: 8%;
        top: 35%;
      }
      #bottom-left-8 {
        position: absolute;
        left: 30%;
        top: 50%;
      }
      #bottom-left-27 {
        position: absolute;
        left: 10%;
        top: 65%;
      }
      #bottom-left-17 {
        position: absolute;
        left: 40%;
        top: 75%;
      }
      #top-right-32 {
        position: absolute;
        right: 50%;
        top: 5%;
      }
      #top-right-12 {
        position: absolute;
        right: 25%;
        top: 8%;
      }
      #top-right-22 {
        position: absolute;
        right: 40%;
        top: 25%;
        width: calc(25px + 5vw);
      }
      #top-right-13 {
        position: absolute;
        right: 15%;
        top: 30%;
      }
      #top-right-14 {
        position: absolute;
        right: 32%;
        top: 50%;
      }
      #top-right-23 {
        position: absolute;
        right: 55%;
        top: 47%;
      }
      #top-right-9 {
        position: absolute;
        right: 10%;
        top: 55%;
      }
      #bottom-left-16 { //Murat
        // position: absolute;
        // right: 45%;
        // top: -25%;
        position: absolute;
        left: 5%;
        top: -25%;
        width: calc(25px + 5vw);
      }
      #bottom-left-4 { //serra
        // position: absolute;
        // right: 20%;
        // top: -20%;
        position: absolute;
        left: 30%;
        top: -38%;
      }
      #bottom-right-29 {
        position: absolute;
        right: 39%;
        top: 2%;
        width: calc(25px + 5vw);
      }
      #bottom-right-20 {
        position: absolute;
        right: 10%;
        top: 4%;
      }
      #bottom-right-11 {
        position: absolute;
        right: 28%;
        top: 14%;
      }
      #bottom-right-9 {
        position: absolute;
        right: 28%;
        top: -10%;
      }
      #bottom-right-25 {
        position: absolute;
        right: 8%;
        top: 33%;
      }
      #bottom-right-30 {
        position: absolute;
        right: 42%;
        top: 32%;
      }
      #bottom-right-24 {
        position: absolute;
        right: 25%;
        top: 41%;
      }
      #bottom-right-28 {
        position: absolute;
        right: 5%;
        top: 65%;
      }
      #bottom-right-31 {
        position: absolute;
        right: 35%;
        top: 65%;
      }
      #bottom-right-15 {
        position: absolute;
        right: 18%;
        top: 80%;
      }

      img {
        height: calc(8px + 4vw);
      }
    }

    .people-1 {
      background: url("/assets/images/people/background/top-left.svg");
      background-position: -20vw -20vh;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .people-2 {
      background: url("/assets/images/people/background/top-right.svg");
      background-position: 20vw -20vh;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .people-3 {
      background: url("/assets/images/people/background/bottom-left.svg");
      background-position: -20vw 4vh;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .people-4 {
      background: url("/assets/images/people/background/bottom-right.svg");
      background-position: 30vw calc(50px + 5vh);
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .people-info {
      position: absolute;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 1;
      width: 300px;
      height: 280px;
      left: 50%;
      top: 25%;
      transform: translate(-50%, 0);
      .info-image {
        position: absolute;
        z-index: 0;
        height: 100%;
        left: 50%;
        transform: translate(-50%, 0);
      }
      .person-info-card {
        margin-top: 280px;
        padding: 0;
        font-size: 13px;
        font-weight: 900;
        .info-card-lines {
          display: flex;
          padding: 5px;
          font-weight: 500;
          list-style: none;
          border: 1px solid rgb(43, 43, 43);
          border-bottom: none;
          overflow: hidden;
          &.unvisible {
            display: none;
          }
        }
        .bottom-line {
          border: 1px solid rgb(43, 43, 43);
        }
        .line-content-head {
          width: 130px;
        }
        .line-content {
          width: 158px;
          font-weight: 400;
          font-size: 12px;
          .new-line{
            white-space: pre-line;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
}
