#footer {
  position: absolute;
  bottom: 0;
  background-color: black;
  color: white;
  width: 100%;
  font-size: calc(1px + 1vw);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70px;
  max-height: 75px;

  img {
    margin: 8px;
    margin-bottom: 10px;
    height: calc(8px + 3vw);
    max-height: 25px;
  }

  .footer-boxes {
    margin: 10px auto;
  }

  .my-footer {
    text-align: left;
  }

  @media (max-width: 767.9px) {
    img {
      margin: 5px;
      margin-bottom: 10px;
      height: calc(5px + 3vw);
      max-height: 25px;
    }

    // height: 80px;
    .left-box {
      padding-left: 5px;
    }
  }
  @media (min-width: 768px) {
    // height: 100px;
    .left-box {
      padding-left: 15px;
    }
  }
}
