#header {
  z-index: 2;
  position: relative;
  .my-header {
    display: flex;
    width: 100%;
    justify-content: center;
    .logo-image-mobile {
      max-height: 50px;
    }
    .logo-image-desktop {
      display: none;
    }
    .links {
      display: block;
      width: 80%;
      margin: auto 0;
      text-align: center;
      align-items: center;

      ul {
        display: flex;
        justify-content: center;
        margin: 0.5em ;
        padding: 0;
      }
      li {
        font-size: calc(2px + 1vw);
        list-style: none;
        padding: 0vw 1.5vw 0vw 1.5vw;
      }
      .pages-buttons {
        color: #918f8f;
      }
      .pages-buttons:active, .pages-buttons:after {
        text-decoration-line: line-through;
        color: black;
        font-weight: 700;
      }
    }
  }

  @media (max-width: 767.99px) {
    .links {
    ul {
      padding: 0;
    }
    li {
      font-size: 9px !important;
    }
    }
  }

  @media (min-width: 768px) {
    .logo-image-mobile {
      display: none;
    }
    .logo-image-desktop {
      display: block !important;
      max-height: 110px;
    }
    .links {
      ul {
        justify-content: flex-end !important;
      }
      li {
        font-size: 16px;
      }
    }
  }
}
