@import "~bootstrap/dist/css/bootstrap-grid.min.css";
@import "~antd/dist/antd.min.css";

@font-face {
  font-family: Campton;
  src: url("/assets/fonts/Campton/Campton-LightDEMO.otf");
  font-weight: 300;
}

@font-face {
  font-family: Campton;
  src: url("/assets/fonts/Campton/Campton-Medium.otf");
  font-weight: 400;
}

@font-face {
  font-family: Campton;
  src: url("/assets/fonts/Campton/Campton-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: Campton;
  src: url("/assets/fonts/Campton/Campton-SemiBold.ttf");
  font-weight: 700;
}

body {
  margin: 0;
  font-family: Campton, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
